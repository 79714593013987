#main > .fader {
	background: url(../images/bg/bg-transparent-green.png) repeat;
}
#main > .course-popup {
	width: 75%;
}
#main > .course-popup .node-section {
	border-top: 1px solid #375459;
	clear: both;
	padding: 27px 40px 40px 40px;
}
#main > .course-popup .node-section:first-child {
	border-top: none;
	padding: 50px 100px 70px 40px;
}
#main > .course-popup .field-featured-image img{
	width: 100%;
    height: auto;
    display: block;
    -ms-interpolation-mode: bicubic;
}
#main > .course-popup .info-boxes-wrapper .col-one-quarter {
	padding: 15px 0 15px 8px;
	border-bottom: none;
	border-left: 1px solid #E7E7E7;
}
#main > .course-popup .info-boxes-wrapper .col-one-quarter {
	width: 25%;
	min-height: 145px;
}
#main > .course-popup .supply-list {
	float: left;
	border-right: 1px solid #e7e7e7;
	padding: 0 20px 20px 0;
	border-bottom: none;
	@include box-sizing;
}
#main > .course-popup .class-notes {
	float: left;
	padding: 20px 0 0 20px;
	@include box-sizing;
}
#main > .course-popup .field-featured-image {
	height: 380px;
  margin-top: 1em;
}
/* Instructor Slide */
#instructor-slide .slide-control {
	top: -21px;
	right: -20px;
}
#main > .course-popup .close {
	text-indent: 0;
	cursor: pointer;
	display: inline-block;
	margin: 0;
	right: inherit;
	top: 555px;
	left: -30px;
	background-color: transparent;
	width: auto;
    border-style: none;
    border-width: none;
    border-color: none;
	/* IE8+ - must be on one line, unfortunately */ 
   -ms-filter: "progid:DXImageTransform.Microsoft.Matrix(M11=6.123031769111886e-17, M12=1, M21=-1, M22=6.123031769111886e-17, SizingMethod='auto expand')";
   /* IE6 and 7 */ 
   filter: progid:DXImageTransform.Microsoft.Matrix(
            M11=6.123031769111886e-17,
            M12=1,
            M21=-1,
            M22=6.123031769111886e-17,
            SizingMethod='auto expand');
}