footer {
	.region-footer {
		&:before {
			@include background-cover;
			display: block;
			background: url("../images/bg/bg-footer.png") no-repeat top center;
			filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/sites/all/themes/tyler/images/bg/bg-footer.png', sizingMethod='scale');
			-ms-filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/sites/all/themes/tyler/images/bg/bg-footer.png', sizingMethod='scale')";
		}
	}
}
footer .region-footer {
	border-top: none;
	overflow: inherit;
	height: auto;
	padding-bottom: 90px;
	&:before {
		display: block;
	}
	.container {
		padding: 65px 0 0 0;
	}
}

#footer .container {
  margin: 0 auto; 
  max-width: 940px;
  width: 940px;
}
.block-footer-contact {
	float: left;
	text-align: left;
	border-left: none;
}
#footer .block-footer-connect.col-one-third {
	width: 33%;
}
footer#footer {
	.region-footer-bottom {
		background: #070707;
		width: 100%;
		height: auto;
		display: block;
		bottom: -5px;
		left: 0;
		padding: em(15) 0;
		.container {
			padding: 0 0 0 0;
		}
	}
}
#footer .col-one-third {
	width: 33%;
}
.footer-nav {
	float: left;
	width: 62%;
}
.footer-nav ul.menu {
	li {
		display: inline-block;
		text-transform: uppercase;
		letter-spacing:1px;
		padding: 0;
		margin: 0;
	}
	a {
		padding: 0 10px;
		text-align: left;
		&:link, &:visited {
			color: #fff;
            font-size: em(10);
            font-weight: 400;
        }
        &:hover, &:active {
        	text-decoration: underline;
        	background-color: #202020;
        }
    }
}
.block-copyrights {
	font-size: em(14);
	float: right;
	width: auto;
	text-align: right;
	background: transparent;
}