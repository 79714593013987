/* List View */
#item-wrap.list .item-info .description {
    width: 100%;
    display: block;
}
/* Student Gallery Slider */
.gallery-thumbnail-toggle {
  display: block;
}
#block-student-work-sort .views-exposed-widgets{
    margin: 0 0 0 0;
    display: block;
}
.gallerySlider .flex-caption {
	background: #000;
}

#single-wrap p.subtitle,
#item-wrap .content-left p.subtitle {
  padding: 0;
  line-height: 1em;
  color: #fff;
  font-style: italic;
  font-size: em(16);
}