.front .masonry-content {
	visibility: visible;
}
.front .container {
  margin: 0 auto;
  max-width: 1200px;
  width: 940px;
}
.front #audience .container {
  width: 940px;
}
.m-block {
    display: inline-block;
    vertical-align: top;
    margin-bottom: 20px;

    &.col4 {
        width: 100%;
        background: white;
    }
    &.col3 {
    	position: relative;
    	width: 100%;
    	width: 74.4%;
    }
    &.col2 {
    	width: 48.8%;
    	overflow: hidden;
    }
    &.col1 {
    	overflow: hidden;
    	width: 23.43%;
    }
}
#audience .audienceFloor {
	padding: 3.000em 0 0 0;
	height: 100% !important;
	height: auto;
}
#audience .centerSlide {
	@include background-cover;
	filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/sites/all/themes/tyler/images/bg/bg-home-diagonal-1.png', sizingMethod='scale');
	-ms-filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/sites/all/themes/tyler/images/bg/bg-home-diagonal-1.png', sizingMethod='scale')";
	border-right: 1px solid #dfdede;
	border-left: 1px solid #ececec;
	-webkit-box-shadow: 0 1px 16px 0px rgba(0, 0, 0, 0.3);
	-moz-box-shadow: 0 1px 16px 0px rgba(0,0,0,0.3);
	box-shadow: 0 1px 16px 0px rgba(0, 0, 0, 0.3);
	filter: progid:DXImageTransform.Microsoft.Shadow(color=#aaaaaa,direction=125,strength=5);
}
#audiencePrevNext li .text-wrap {
	background-color: #fff;
	overflow: hidden;
	cursor: pointer;
	margin: 5.313em 0 0 0;
	height: 50px;
}
#audiencePrevNext li.right {
	position: absolute;
	right: -1px;
}
#audiencePrevNext li.left {
	position: absolute;
	left: 0px;
}
#audiencePrevNext #textNextNav {
	/* IE8+ - must be on one line, unfortunately */ 
   -ms-filter: "progid:DXImageTransform.Microsoft.Matrix(M11=-1.836909530733566e-16, M12=-1, M21=1, M22=-1.836909530733566e-16, SizingMethod='auto expand')";
   /* IE6 and 7 */ 
   filter: progid:DXImageTransform.Microsoft.Matrix(
            M11=-1.836909530733566e-16,
            M12=-1,
            M21=1,
            M22=-1.836909530733566e-16,
            SizingMethod='auto expand');
}
#audiencePrevNext #textPrevNav {
	/* IE8+ - must be on one line, unfortunately */ 
   -ms-filter: "progid:DXImageTransform.Microsoft.Matrix(M11=6.123031769111886e-17, M12=1, M21=-1, M22=6.123031769111886e-17, SizingMethod='auto expand')";
   /* IE6 and 7 */ 
   filter: progid:DXImageTransform.Microsoft.Matrix(
            M11=6.123031769111886e-17,
            M12=1,
            M21=-1,
            M22=6.123031769111886e-17,
            SizingMethod='auto expand');
}
.col2-slide .flex-caption {
	z-index: 25;
	top: 24%;
	background: transparent;
	max-width: 150px;
	width: auto;
	min-height: auto;
	right: 25px;
	left: inherit;
}
.col2 .slides a.button.more {
	right: 0;
	float: right;
	background-color: rgba(138, 43, 35, 0.8);
	background-position: 98% -429px;
	border: 1px solid $darkBlue;
	line-height: 22px;
	padding: 0 25px 0 1.25em;
	display: block;
	font-size: 14px;
}
.col2 .slides a.button.more {
	color: #fff;
	&:hover{
		color: $darkBlue;
	}
}
#audience .prospective .col2 .slides a.button.more {
	background-color: rgba(191, 212, 81, 0.6);
	border: 1px solid $green;}
#audience .current .col2 .slides a.button.more {
	background-color: rgba(249, 249, 248, 0.4);
	border: 1px solid $cyan;
}
#audience .alumni .col2 .slides a.button.more {
	background-color: rgba(252, 141, 74, 0.6);
	border: 1px solid $orange;
}
.col2 ul.flex-direction-nav {
    top: 37%;
	right: 7%;
}
.block-video .author-caption {
	background: #fff;
}
/* Audience Callout Block */
#audience .block-hero-callout{
	max-height: 395px;
	overflow: hidden;
	padding-left: 1.155em;
	width: 72.4%;
}
#audience .block-hero-callout .caption {
	width: 180px;
	left: 0;
	p{display: block;}
}
/* Section Block Title */
.block-audience-title h1 {
	text-indent: -9999px;
	height: 406px;
	line-height: 0;
	display: block;
}
/* Block Half Split */
.half-split {
	height: 360px;
	position: relative;
}
.half-split .content {
	width: 40%;
	position: absolute;
	right: 20px;
	bottom: 0;
	display: block;
	background: #fff;
	height: 50%;
	h3{ font-size: 1.250em;}
  h2{ font-size: em(20);}
}
.half-split .content p {
	display: block;
}
.block-img a.imagelink:hover .overlay,
.block-img a.imagelink .overlay.focus{
	background: url(../images/bg/bg-transparent-green.png) repeat;
}
/* Block Events/Blog */
.block-event-blog {
	background: #fff;
	position: relative;
	
	h5 {
		padding: 1.25em 0 1.25em 20px;
		border-top: 3px solid $red;
		color: $red;
	}
	.content {
		padding: 5px 20px 35px 20px;
	}
}

.block-event-blog h2 {
  line-height: 1.250em;
  font-weight: 700;
  margin-bottom: 0.313em;
  color: #001c25;
  font-size: em(20);
}

.block-event-blog .blog-tag,
.block-event-blog .event-tag {
	padding: 1.25em 0 1.25em 20px;
	border-top: 3px solid $red;
	color: $red;
  font-family: "proxima-nova", Helvetica,Arial,sans-serif;
  line-height: 1.14286em;
  font-size: 0.750em;
  letter-spacing: 0.125em;
  color: #001c25;
  font-weight: 800;
  text-transform: uppercase;
}

.prospective .block-event-blog {
  .blog-tag,
  .event-tag {
    border-top: 3px solid $green;
    color: $darkGreen;
  }
}
.current .block-event-blog {
  .blog-tag,
  .event-tag {
    border-top: 3px solid $cyan;
    color: $darkCyan;
  }
}
.alumni .block-event-blog {
  .blog-tag,
  .event-tag {
    border-top: 3px solid $orange;
    color: $darkOrange;
  }
}

.block-event-blog .blog-date,
.block-event-blog .event-date {
  font-family: "ff-dagny-web-pro", Helvetica, Arial, sans-serif;
  line-height: 1.25em;
  font-size: 0.688em;
  font-weight: 700;
  font-style: italic;
  color: #c23022;
}

/* Block Callout Links */
#audience .block-quick-links,
#block-program-masonry .block-quick-links {
	background-color: $darkBlue;
	margin-left: 0;
}
.block-quick-links {
	display: block;
	min-height: 150px;
	background-color: #a3acaf;
	margin-bottom: 20px;
}
/* Program-Masonry */
#block-program-masonry .masonry-title .block-label {
	color: $darkBlue;
	font-weight: 600;
	font-size: em(10);
	line-height: 37px;
	height: 37px;
	background-color: #fff;
	position: relative;
	width: 43%;
	margin: 0 0;
}