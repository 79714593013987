h1 {
    font-size: 65px;
    font-weight: 100;
    text-transform: uppercase;
    color: $red;
    line-height: em(16);
}
h5 {
    font-size: 14px;
}
.node-type-academic-program h1,
.node-type-landing-page h1 {
    margin-top: -57px;
    margin-bottom: em(5);
}
.front-loader { display: none;}
/* Fluid Grids - START
*********************************/
.col-full {
    min-height: 100%;
    width: 100%;
    height: 100%;
}
.col-one-half {
    width: 50%;
}
.col-one-third {
    width: 33.333%;
}
.col-one-quarter {
    width: 25%;
}
.col-three-quarters {
    width: 74.444%;
}
.col-two-thirds {
    width: 66.666%;
}
/* info boxes */
.grid-view,
.info-boxes-wrapper {
    margin: 30px 0 0px -20px;
}
.info-block {
    background-color: #fff;
    padding: 20px 20px 30px 20px;
    margin: 0 0 0 0;
}
/* grid view */
.grid-view .grid {
    position: relative;
    padding: em(20) 0 em(20) em(20);
}
.grid-view li .item-content a.more span{
    display: inline-block;
}
/* Search Results */
.page-search .search-snippet-text {
    padding: 60px 0 30px 0;
}
.page-search #search-form {
    position: absolute;
    top: 50px;
    background-color: rgba(255, 255, 255, 0.6);
    right: 0;
    margin-bottom: em(25);
}
ul.event-info {
    width: 100%;
}
ul.event-info li {
    display: inline;
    width: auto;
}
ul.event-info li .field-room-number{
    display: inline;
}
/* rollover bg */
li .bg{
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: auto;
}
li:hover > .bg {
    margin: -18px -18px;
    padding: 18px 18px;
    background: #fff;
}