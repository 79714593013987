/*
 * Temple Tyler website Redesign
 * @Description IE8 and Below styles
 * @author Putra Roeung
 * @copyright 2012 Bluecadet, info@bluecadet.com
 ***********************************************/

/*
// Legacy IE support
*/
$legacy-support-for-ie6: false;
$legacy-support-for-ie7: false;
$legacy-support-for-ie8: true;

/*
 * IE is bananas
 *
 ************************************************************************************************/
/* IE has something against circles and shadows! Need to bring in PIE to take care of it */
a.button,
.block-text-callout ul.field-action-link li a,
.block-program-quick-links a,
.field-program-requirements-link a.button,
.sidebar div.blog-tags div.content a,
.loading_ajax,
.gallerySlider ul.flex-direction-nav li a,
ul.field-artwork-medium li a,
.flexslider ul.flex-direction-nav li a,
.flexSlide ul.flex-direction-nav li a,
.flexCarousel ul.flex-direction-nav li a,
.flex-control-paging li a,
#block-events .flex-slide ul.flex-direction-nav li a,
li:hover > .grid-bg,
li:hover > .bg,
.tooltip .tooltip-holder,
.section-event-calendar .view-event-calendar .view-footer a,
#block-admissions-timeline .timeline-container .month-name span.count,
#block-admissions-timeline .timeline-container .timeline > ul > li .events-cont .event{
  behavior: url(/sites/tyler/themes/tyler/js/lib/PIE/PIE.htc); 
}

/* Sass Mixins */
@import	"mixins";

/* Base Styles */
@import	"base";

/* Page - START
*********************************/
@import "pages";

/* Header - START
*********************************/
@import "header";

/* Breadcrumb - START
*********************************/
@import "breadcrumb";

/* Homepage Audience Masonry - START
*********************************/

@import "homeAudience";

/* Content Bottom - START
*********************************/

@import "contentBottom";

/* Footer - START
*********************************/
@import "footer";

/* FlexSlider - START
*********************************/
@import "flexslider";

/* Sort Bar and Grid - START
*********************************/
@import "sortView";

/* Student Work - START
*********************************/
@import "studentWork";

/* Popup View and Courses - START
*********************************/
@import "popup";

/* Calendar - START
*********************************/
@import "calendar";