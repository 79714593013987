.view-event-exhibitions .view-filters,
.view-exhibitions .view-filters,
.view-event-calendar .view-filters,
.fc-button-today,
.fc-header-right *{
  display:none;
}
/* Datepicker - START
*********************************/
.feature-block-calendar .ui-corner-all {
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
}
.feature-block-calendar .ui-widget-content {
  border: 0px solid #aaaaaa;
}
#main #ui-datepicker-div {
  font-size: 100%;
  background: none;
  border-right:none;
  border-bottom:none;
  z-index: 9999;
}
#main .ui-datepicker {
  width: auto; 
  background: #001c25;
  -webkit-border-radius: 0;
     -moz-border-radius: 0;
          border-radius: 0;
  font-family: "ff-dagny-web-pro", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: em(14);
  padding: 10px;
}
#main .ui-datepicker .ui-datepicker-header { padding: 0 0; }
#main .ui-datepicker th,
#main .ui-datepicker td {
  padding: 0 7px;
  text-align: left;
  line-height: 22px;
  font-size: em(14);
}
#main .ui-datepicker th {
  color: $lightRed;
  font-weight: 700;
  padding-bottom: 5px;
}
#main .ui-datepicker tbody {
  border: 0;
}
#main .ui-datepicker td span,
#main .ui-datepicker td a {
  padding: 0;
  color: #798589;
  text-align: left;
}
#main .ui-datepicker .ui-state-default {
  color: #fff;
  background: none;
  border: 0;
  opacity: 1;
}
#main .ui-datepicker .ui-state-disabled {
  opacity: 1;
  filter:Alpha(Opacity=100);
}
#main .ui-datepicker td a {
  color: #fff !important;
  font-weight: 800 !important;
  font-size: em(15) !important;
}
#main .ui-datepicker td.ui-datepicker-unselectable {
  font-weight: 400 !important;
}
#main .ui-datepicker .ui-state-default:hover,
#main .ui-datepicker .ui-state-default.ui-state-active,
#main .ui-datepicker .event a {
  color: $lightRed !important;
}
#main .ui-datepicker .event a{
  opacity:0.8;filter:Alpha(Opacity=80)
}
#main .ui-datepicker .event:hover a{
  opacity:1;filter:Alpha(Opacity=100)
}
#main .ui-datepicker .ui-widget-header {
  background: none;
  border: 0;
  color: #fff;
  padding: 0;
  margin: 0 0 10px 0;
}
#main .ui-datepicker .ui-datepicker-title {
  margin: 5px 60px 0 7px;
  text-align: left;
  overflow: hidden;
  font: 700 23px/28px "ff-dagny-web-pro", Helvetica, Arial, sans-serif;
}
#main .ui-datepicker .ui-datepicker-title .ui-datepicker-year {
  display: none;
}
#main .ui-datepicker .ui-datepicker-prev,
#main .ui-datepicker .ui-datepicker-next {
  background: url(../images/ui/ui-arrows-sprites-2.png) no-repeat 0 -416px;
  width: 20px;
  height: 28px;
  right: 0;
  top: 2px;
  cursor: pointer;
}
#main .ui-datepicker .ui-datepicker-prev {
  background-position: 0 0;
  left: auto;
  right: 33px;
}
#main .ui-datepicker .ui-datepicker-prev:hover {
  background-position: 0 -82px;
  border: 0;
}
#main .ui-datepicker .ui-datepicker-next:hover {
  background-position: 0 -498px;
  border: 0;
}
#main .ui-datepicker .ui-datepicker-prev span,
#main .ui-datepicker .ui-datepicker-next span{
  display: none;
}
.view-event-calendar .sort-bar ul li#tid-87,
.view-event-calendar .sort-bar ul li#tid-86 {
  border-bottom: none;
  padding: 0 0 0 0;
  display: none;
  visibility: hidden;
}
.view-event-calendar .filter-dropdown {
  width: 28%;
}
.section-event-calendar .view-event-calendar .view-footer a {
  float: right;
  display: inline-block;
  font-size: em(14);
  line-height: em(14);
  padding: 0.375em em(40) 0.313em em(20);
  border-radius: em(10);
  border: 1px solid #8b8885;
  background: url(../images/ui/ui-icons-sprites.png) no-repeat;
  background-position: 96% -1624px;
  margin-top: 15px;
  color: #fff;
  &:hover, &:focus {
    background-color: #fff;
    color: $red;
  }
}
.tooltip .tooltip-holder .event-title a,
.tooltip .tooltip-holder .event-more-link a{
  color: $red;
  &:hover {
    color: #fff;
  }
}
#page .fullcalendar table thead tr th.ui-widget-header{
  border:none;
  background:none;
  text-align:left;
  font-family: "proxima-nova", Helvetica,Arial,sans-serif;
  line-height: 1.14286em;
  font-size: 0.750em;
  letter-spacing: 0.125em;
  color: #fff;
  font-weight: 800;
  text-transform: uppercase;
  padding: 0 0 5px 10px;
}
#page .fullcalendar .ui-widget-content.fc-other-month{
  background:#cbc7c2;
}
#page .fullcalendar .ui-widget-content{
  border-color:#22383e;
}
#page .fullcalendar .ui-widget-content .fc-day-number{
  padding:9px 12px 11px 12px;
}
#page .fullcalendar .ui-widget-content .fc-day-content{
  padding:0px 2px 6px 2px;
}
#page .fullcalendar .ui-widget-content.fc-other-month *{
  visibility:hidden !important;
  height:0;
}
#page .fullcalendar .fc-day-number{
  font-family: "ff-dagny-web-pro", Helvetica, Arial, sans-serif;
  color:#6b6865;
  font-size: em(26);
  line-height:26px;
  font-weight:300;
  float:left;
}
#page .fc-event,
#page .fc-event a{
  font-size: em(12);
  line-height:16px;
  font-family: "ff-dagny-web-pro", Helvetica, Arial, sans-serif;
  font-weight:bold;
  margin:0;
  border:none;
  background:none;
  color:#1f273a;
}
#page .fc-event-default.featured,
#page .fc-event-default.featured .fc-event-skin,
#page .fc-event-default.featured .fc-event-time,
#page .fc-event-default.featured a {
  border-style: solid;
  color: #FFFFFF;
  font-size: em(12);
  line-height:16px;
  font-family: "ff-dagny-web-pro", Helvetica, Arial, sans-serif;
  font-weight:bold;
  border:none;
  background:none;
}
#page .fullcalendar .fc-event-default.featured{
  background:#ff0606;
  border-radius:12px;
}
#page .fullcalendar .fc-event.few-days{
  background:#e6e3df;
  border-radius:8px;
  .fc-event-title {
    color: $darkBlue;
  }
}
#page .fullcalendar .fc-event-inner{
  margin:0;
  padding:0;
  border:none;
  background:none;
}
#page .fullcalendar .fc-event-default,
#page .fullcalendar .fc-event-default,
#page .fullcalendar .fc-event-skin,
#page .fullcalendar .fc-event-default .fc-event-time,
#page .fullcalendar .fc-event-default a{
  background: none;
  color: #1f273a;
  border:none;
  padding:0;
  margin:0;
  font-size: em(14);
  line-height:16px;
  font-family: "ff-dagny-web-pro", Helvetica, Arial, sans-serif;
  font-weight:bold;
}
#page .fullcalendar .fc-event{
  padding:0px 8px;
  margin-bottom:7px;
}
#page .fullcalendar .fc-event.few-days,
#page .fullcalendar .fc-event.featured{
  padding: 5px 0px 5px 8px;
  margin-left: 2px;
}
#page .fullcalendar .fc-event .fc-event-time{
  color:#c60000;
  padding-right:2px;
}
#page .fullcalendar .fc-event.featured .fc-event-time{
  color:#001c25;
}
#page .fc-event-default .fc-event-inner:hover{
  color:#da3a2b;
}
#page .fc-event-default.featured .fc-event-inner:hover{
  color:#fff;
}
#page .fc-event-default .fc-event-inner:hover .fc-event-title{
  text-decoration:underline;
}
.cal-nav-control a {
  background-color: $red;
  top:50%;
}
.cal-list-nav-control a {
  top:50%;
}
.cal-nav-control a.next,
.cal-list-nav-control a.next{
  background-position: 8px -579px;
  right:-7.22%;
}
.cal-list-nav-control a.prev{
  background-position: 7px -492px;
  left:-7.22%;
}
.cal-nav-control a.prev{
  background-position: 7px -492px;
  left:-7.22%;
}
.page-events-list .block-page-title h1,
.page-event-calendar .block-page-title h1{
  background:url(../images/ui/ui-icons-sprites.png) no-repeat;
  background-position: 0 -133px;
  padding-left:24px;
  line-height: 20px;
  font-size: 0.750em;
  letter-spacing: 0.125em;
  color: #fff;
  font-weight: 800;
  text-transform: uppercase;
  font-family: "proxima-nova", Helvetica,Arial,sans-serif;
}
/* Event List */
.fc-header-title h2,
h2.current-month{
  color:$grey;
  font-weight:300;
  font-family: "proxima-nova",Helvetica,Arial,sans-serif;
  text-transform:uppercase;
  margin:0;
  padding:0;
  width: 100%;
  font-size: em(60);
  line-height:60px;
}
.events-list .node-event .event-name,
.event-row.node-event .event-name{
  display:inline-block;
  padding: 15px 15px;
  font-size: em(14);
  width: 68.55%;
  min-height: 59px;
  border-right: 1px solid #fff;
  @include box-sizing;
}
.events-list .views-row,
.events-list .views-row.views-row-last,
.event-row.node-event {
  border-top:1px solid #fff;
  border-right:none;
  border-left:none;
}
.page-student-life-the-what .events-list {
  margin-top: 35px;
}
.events-list .node-event .date-event,
.event-row.node-event .date-event{
  background:#817e7b;
  color:#fff;
  font-size: em(58);
  font-weight:300;
  font-family: "proxima-nova",Helvetica,Arial,sans-serif;
  text-align:center;
  float:left;
  padding:0 0 0 0;
  border-right:1px solid #fff;
  vertical-align: middle;
  display: table-cell;
  width: 18%;
  min-height: 92px;
  @include box-sizing;
}
.events-list a.button {
  border-top-color: #8b8885;
  color: #fff;
}
.events-list .views-row-last{
  border-bottom:1px solid #fff;
}