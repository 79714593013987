#block-events .flex-slide .slides,
#block-blog {
	display: block;
}
.no-js #block-events .flex-slide .slides,
.no-js #block-blog {
	display: block;
}
.flexslider {
	img {
		padding-top: 25px;
		width: 96.888%;
	}
}
.flexCarousel ul.slides > li{
	margin: 0 0 0 20px;
}
.flexslider .slide-control {
	position: absolute;
	top: 0;
	right: -1px;
	width: 301px;
	height: 301px;
	overflow: hidden;
	display: block;
	z-index: 5;
    background: url(../images/bg/bg-slide-control.png) no-repeat;
    background-position: 0 -991px;
    h2 {
    	text-align: right;
		position: absolute;
    	top: 15%;
    	right: 40px;
    	text-align: right;
    }
}
.col2 .flexslider .slide-control {
	right: -8px;
}
/* Direction Nav */
.flexslider .flex-direction-nav {
	top: 22.5%;
	right: 6.55%;
}
.flexCarousel .flex-direction-nav,
.flex-slide .flex-direction-nav {
	display: block;
}
.flex-control-nav {
	display: none;
}
.flex-caption {
	min-width: 205px;
	max-width: 66%;
}
/* Video Gallery START */
.video-gallery .video {
	position: relative;
	float: left;
	margin: 0 0 18px 0;
}
.video-gallery .video-wide {
	width: 620px;
	float: left;
	margin: 0 20px 20px 0;
}
.view-video-gallery h1 {
	font-size: em(65);
	margin: 40px 0 15px 0;
}
.view-video-gallery h1 span {
	right: 0;
	display: block;
	height: 3px;
	background: #fff;
	z-index: -1;
	position: absolute;
	top: 43px;
	width: 66%;
}
.view-video-gallery .gallerySlider .slides li.flex-active-slide {
	margin-left: 10px;
}