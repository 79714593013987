header {
  &#header{
    position: relative;
    background-color: white;
    height: 165px;
    z-index: 120;
    .container {
      margin: 0 auto;
      @include boxit(vertical);
      display: block;
    }
  }
}
.logo {
  width: 310px;
  height: 93px;
  padding: 15px 0 0 0;
  margin: 0 0 0 0;

  .logo-tyler {
    display: block;
    width: 305px;
    height: 40px;
    overflow: hidden;
    text-indent: 100%;
    white-space: nowrap;
    background: url("../images/logo-tyler-lg.png") no-repeat;
    background-size: 305px 40px;
    padding: 0;
    border-left: none;
  }
  .logo-temple {
    display: block;
    overflow: hidden;
    text-indent: -9999px;
    white-space: nowrap;
    width: 219px;
    height: 15px;
    margin: 0 0 29px 0;
    background-size: 219px 15px;
    background: url("../images/logo-temple-lg.png") no-repeat;
  }
}
/* Mobile Nav Button Toggle */
#mobile-nav-toggle {
  display: none;
}
/* Mobile Bottom Menu Closer */
#mobile-menu-closer {
  display: none;
}
#mobile-menu-closer a {
  background: url(../images/ui/ui-mobile-menu-open.png) no-repeat bottom center;
  height: 31px;
  width: 100%;
  display: block;
  text-indent: -9999px;
}
/* Mobile Nav Wrapper */
#nav-wrapper {
  width: 100%;
  height: auto;
  display: block;
}
/* Audience mobile Nav - Jump links */
.front #audience-mobile {
  display: none;
}
#audience-mobile {
  display: none;
  width: 100%;
  background: #fff url(../images/bg/bg-mobile-audience.png) repeat-x bottom;
  
  ul.audience {
    width: 100%;
    clear: right;
    border-left: none;
    border-top: 1px solid #fff;
    padding: 0;
    margin: 0;
    line-height: 2.500em;
    li {
      text-transform: uppercase;
      font-family: "proxima-nova", Helvetica, Arial, sans-serif;
      font-size: 0.688em;
      font-weight: 700;
      letter-spacing: 0.144em;
      margin: 0 -4px 0 0;
      text-align: center;
      background: none;
      display: inline-block;
      line-height: none;

      &:first-child{
        margin-left: 0;
        border-left: none;
      }
      a {
        border-top: none;
        padding: 0 0 0 0;
        background: none;
        color: #b4b0b1;
        display: block;
        span {
          display: none;
        }
      }
      &.prospective {
        width: 33.333%;
        a {
          color: #b4b0b1;
          border-right: 1px solid #f0f1f1;
        &:before {
          content: "";
          display: block;
          border-top: 4px solid $green;
        }
          &:hover, &:active, &.audienceLinkActive {
            color: #839912;
          }
        }
      }
      &.current {
        width: 33.333%;
        a {
          color: #b4b0b1;
          border-right: 1px solid #f0f1f1;
          &:before {
            content: "";
            display: block;
            border-top: 4px solid $cyan;
          }
          &:hover, &:active, &.audienceLinkActive {
            color: #1c897c;
          }
        }
      }
      &.alumni {
        width: 33.333%;
        margin-right: 0;
        a {
          color: #b4b0b1;
          border-right: none;
          &:before {
            content: "";
            display: block;
            border-top: 4px solid $orange;
          }
          &:hover, &:active, &.audienceLinkActive {
            color: #c18608;
          }
        }
      }
    }
    #activeArrow{
      display: block;
      margin-left:14.5%;
      bottom: -9px;
      border-left: 14px solid transparent;
      border-right: 14px solid transparent;
      border-top: 13px solid #fff;

      .prospectiveArrow{
        margin-left:16.5%;
      }
      .currentArrow{
        margin-left:47.5%;
      }
      .alumniArrow{
        margin-left:81.7%;
      }
    }
  }
}

/* Primary Nav - Active Trail Style */
#header .primary ul,
#header .audience ul {
  li.active-trail:after {
    content: "";
    display: block;
    position: absolute;
    margin: 0;
    left: 45%;
    width: 0;
    height: 0;
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
    border-top: 8px solid #fff;
  }
  li ul > li.active-trail:after {
    display: none;
  }
}
#header .audience ul {
  li.prospective.active-trail:after {
    left: 17%;
  }
  li.current.active-trail:after {
    left: 50%;
  }
  li.alumni.active-trail:after {
    left: 82%;
  }
}

/* ======== Primary Nav - Base Style ======== */
.navigation.primary {
  margin: 0 0 0 -1.55%;
  width: 67.88%;
  height: auto;
  float: left;
}
.navigation.primary ul.menu {
  position: relative;
  margin-right: -50px; /* typekit load fixes*/
}
.navigation.primary ul.menu > li {
  margin: 0;
  padding:0;
  font-weight: 600;
  display: inline;
  position: relative;

  a {
    background: url(../images/ui/ui-arrows-sprites-2.png) no-repeat;
    background-position: 95% -1575px;
    display: inline-block;
    padding:0 3.6% 0 2.6%;
    margin-right: -4px;
    line-height: em(66);
    font-size: em(14);

    &:link, &:visited {
      color: #000;
      background-color: #fff;
      background-position: 95% -1575px;
    }
    &:hover {
      color: #fff;
      background-color: $darkBlue;
      background-position: 95% -1575px;
    }
    &.open, &:active, &:focus {
      color: #fff;
      background-color: $darkBlue;
      background-position: 95% -1635px;
    }
    &.active, &.active-trail {
      color: $red;
    }
    &.active:hover {
      color: #fff;
    }
  }
  &:hover > a, &:hover.dropdown > a,
  &:focus > a, &:focus.dropdown > a {
    color: #fff;
    background-color: $darkBlue;
    background-position: 95% -1635px;
  }
  &:hover.dropdown ul.menu,
  &:focus ul.menu,
  ul.menu.show-menu {
    display: block;
  }
}
.navigation.primary ul.menu li.expanded > ul li {
  height: auto;
  display: inline-block;
  a {
    line-height: 1.500em;
  }
}
/* ======== Audience Nav - Base Style ======== */
.navigation.audience {
  width: 33.55%;
  float: right;
  padding: 0 0 0 0;
  margin: 0 0 0 0;
  border-left: 1px solid #f4f4f4;
  border-top: none;
  height: em(57);
  position: relative;
}
.navigation.audience ul.menu > li {
  display: inline-block;
  text-transform: uppercase;
  font-family: "proxima-nova", Helvetica,Arial,sans-serif;
  font-weight: 700;
  letter-spacing: em(1);
  margin: 0 -4px 0 0;
  padding: 0;

  &:first-child{
    margin-left: 0;
  }
  a {
    font-size: em(11);
    height: 45px;
    line-height: em(25);
    display: inline-block;
    cursor: pointer;
    padding: 12px 0 0 15px;
    span {
        display: block;
      }
    }

  &.prospective {
      width: 38%;
      a {
        color: $darkGreen;
        background: url(../images/ui/ui-arrows-sprites-2.png) no-repeat;
        background-position: 85% -1217px;
        border-right: none;
        padding: 12px 0 0 20px;
        &:before {
          border-top: none;
        }
        &:hover, &:active {
          color: #fff;
          background-color: $green;
          background-position: 85% -1217px;
        }
        &.open, &:focus {
          color: #fff;
          background-color: $green;
          background-position: 85% -1392px;
        }
      }
    }
    &.current {
      width: 33%;
      a {
        color: $darkCyan;
        background: url(../images/ui/ui-arrows-sprites-2.png) no-repeat;
        background-position: 93% -1273px;
        border-right: none;
        &:before {
          border-top: none;
        }
        &:hover, &:active {
            color: #fff;
            background-color: $cyan;
            background-position: 93% -1273px;
          }
          &.open, &:focus {
            color: #fff;
            background-color: $cyan;
            background-position: 93% -1448px;
          }
      }
    }
    &.alumni {
      width: 29%;
      margin-right: 0;
      a {
        color: $darkOrange;
        background: url(../images/ui/ui-arrows-sprites-2.png) no-repeat;
        background-position: 93% -1333px;
        &:before {
          border-top: none;
        }
        &:hover, &:active {
          color: #fff;
          background-color: $orange;
          background-position: 93% -1333px;
        }
        &.open, &:focus {
          color: #fff;
          background-color: $orange;
          background-position: 93% -1508px;
        }
      }
    }
  &:hover > a.prospective, &:hover.dropdown > a.prospective{
    color: #fff;
    background-color: $green;
    background-position: 85% -1392px;
  }
  &:hover > a.current, &:hover.dropdown > a.current{
    color: #fff;
    background-color: $cyan;
    background-position: 93% -1448px;
  }
  &:hover > a.alumni, &:hover.dropdown > a.alumni{
    color: #fff;
    background-color: $orange;
    background-position: 93% -1508px;
  }
  &:hover.dropdown ul.menu,
  &:focus ul.menu,
  ul.menu.show-menu {
    display: block;
  }
}
/* ======== Audience Nav - Active Arrow Style ======== */
.navigation.audience #activeArrow{
  position:absolute;
  margin-left:16.5%;
  bottom: -8px;
  padding: 0px;
  width: 0; 
  height: 0; 
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-top: 8px solid #fff;

  &.prospectiveArrow{
    margin-left:16.5%;
  }
  &.currentArrow{
    margin-left:50.5%;
  }
  &.alumniArrow{
    margin-left:82.5%;
  }
}
/* ======== Primary & Audience Nav - Dropdown Base Style ======== */
.navigation.primary ul.menu li.expanded ul {
  width: 260px;
}
.navigation.audience ul.menu li.expanded ul {
  width: 84%;
  left: 0;
}
.navigation.primary ul.menu li.expanded ul,
.navigation.audience ul.menu li.expanded ul{
  height: auto;
  padding: 25px 25px 25px 25px;
  border-left: none;
  position: absolute;
  display: none;
  background: #fff;
  margin: 0 0 0 0;
  @include box-shadow;
  z-index: 500;
}
.navigation.primary ul.menu li.expanded ul {
  float: left;
  left: 0;
}
.navigation.primary ul.menu li.expanded > ul li,
.navigation.audience ul.menu li.expanded > ul li {
  width: 100%;
  text-transform: uppercase;
  letter-spacing: 0.094em;
  color: $darkBlue;
  margin: 0px 0;
  font-family: "proxima-nova", Helvetica,Arial,sans-serif;

  &.first,
  &:first-child{
    margin-top: 0;
    margin-bottom: 0;
  }
  &.last,
  &:last-child{
    margin-bottom: 0;
  }
  a {
    line-height: 0;
    font-size: em(12);
    height: auto;
    color: $darkBlue;
    background: url("../images/ui/ui-arrows-sprites.png") no-repeat;
    background-position: 100% -434px;
    padding: 0 25px 0 0;
    margin: 0;
    border: none;
    display: inline;

    &:before {
      content: "";
      display: block;
      border-top: none;
    }

    &:link, &:visited {
      font-weight: 700;
    }
    &:hover, &:active, &:focus {
      color: $red;
      background: url("../images/ui/ui-arrows-sprites.png") no-repeat;
      background-position: 100% -434px;
    }
  }
}
.navigation.primary ul.menu li.expanded > ul li.expanded > ul,
.navigation.audience ul.menu li.expanded > ul li.expanded > ul {
  width: 100%;
  float: none;
  display: block;
  margin: 6px 0 0 10px;
  padding: 0;
  position: relative;
  background: none;
  -webkit-box-shadow: none;
      -moz-box-shadow: none;
        box-shadow: none;
  li {
    font-weight: 400;
    text-transform: none;
    letter-spacing: 0;
    margin: 3px 0px;
    font-family: "ff-dagny-web-pro", Helvetica,Arial,sans-serif;

    &.last,
    &:last-child{
      margin-bottom: 0px;
    }
    a {
      background: none;
      padding: 0;
      font-size: em(14);

      &:link, &:visited {
        font-weight: 400;
      }
      &:hover, &:active, &:focus {
        color: $red;
        font-weight: 600;
        border-bottom: 1px dotted $red;
        border-left: none;
      }
    }
  }
}
.front .navigation.audience ul.menu li.expanded > ul li.overview a.active-trail {
  color: $red;
  font-weight: 600;
  background-position: 100% -434px;
}
.front .navigation.audience ul.menu li.expanded > ul li.overview a,
.front .navigation.audience ul.menu li.expanded > ul li.overview a.active-trail {
  &:hover {
    font-weight: 600;
    cursor: auto;
    background-color: transparent;
    background-position: 100% -434px;
  }
}
.navigation.audience ul.menu li ul.menu-lvl-4 {
  display: none;
}
.navigation.primary ul.menu li.expanded > ul.menu li.expanded > ul,
.navigation.audience ul.menu li.expanded > ul.menu li.expanded > ul {
  display: block;
}
.navigation.primary ul.menu li.expanded > ul.menu li.expanded > ul li > ul,
.navigation.audience ul.menu li.expanded > ul.menu li.expanded > ul li > ul{
  display: none;
}
/* Primary & Audience - Dropdown menu lines */
.navigation.primary ul.menu li.expanded > ul.menu,
.navigation.audience ul.menu li.expanded > ul.menu {
  &:before {
    content: "";
    display: block;
    height: 4px;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: $darkBlue;
  }
}
.navigation.primary ul.menu li.expanded > ul.menu,
.navigation.audience ul.menu li.expanded > ul.menu{
    &:before {
      height: 4px;
    }
}
.navigation.audience ul.menu li.prospective > ul,
.navigation.audience ul.menu li.current > ul,
.navigation.audience ul.menu li.alumni > ul{
    &:before {
      height: 4px;
    }
}
.navigation.audience ul.menu li.prospective > ul.menu {
  &:before {
    background-color: $green;
  }
}
.navigation.audience ul.menu li.current > ul.menu {
  &:before {
    background-color: $cyan;
  }
}
.navigation.audience ul.menu li.alumni > ul.menu {
  &:before {
    background-color: $orange;
  }
}
.navigation.primary ul.menu li.expanded > ul li.expanded > ul.menu,
.navigation.audience ul.menu li.expanded > ul li.expanded > ul.menu {
  &:before {
    display:none;
  }
}
/* ======== Utility Nav - Dropdown Style ======== */
#utility {
  height: 40px;
  width: 60%;
  top: 0;
  right: 0;
  position: absolute;
  z-index: 20;

  .navigation.utility {
    ul.menu {
      width: 96%;
      height: 28px;
      float: left;
      display: block;
      line-height: 1.625em;
      background: #e9e9e9 url(../images/bg/bg-top-menu-sprites.png) repeat-x;
      background-position: 0 -55px;
      position: relative;

      &:before {
        zoom: 1;
        content: "";
        display: block;
        width: 30px;
        height: 28px;
        background: url(../images/bg/bg-top-menu-sprites.png) no-repeat;
        background-position: 0 -6px;
        position: absolute;
        margin-left: -28px;
        left: 0;
      }
      li {
        display: inline;
        margin: 0;
        background: url(../images/ui/ui-icons-sprites.png) no-repeat;
        background-position: 0 -1560px;
        padding: 0 0.438em 0 1.000em;

        &:first-child{
          background: none;
        }
      }
      a {
        background: url(../images/ui/ui-icons-sprites.png) no-repeat;
        background-position: 30px 30px;

        &:link, &:visited {
          font-size: 0.688em;
          color: #001c25;
          font-weight:600;
        }
        &:hover, &:active, &.active-trail, &:focus {
          color: $darkRed;
        }
        &.blog {
          background-position: -1px -333px;
          padding-left: 22px;
          &.active-trail {
            background-position: -1px -528px;
          }
        }
        &.calendar {
          width: auto;
          background-position: -1px -2px;
          padding-left: 22px;
          &.active-trail {
            background-position: -1px -200px;
          }
        }
      }
    }
  }
}
#utility .navigation.utility ul.menu > li ul.menu {
  display: none;
}
/* .block-search - base style*/
.block-search:before {
  content: "";
  display: block;
  width: 53px;
  height: 50px;
  background: url(../images/bg/bg-top-menu-sprites.png) no-repeat;
  background-position: 0 -120px;
  position: absolute;
  margin-left: -53px;
}
.block-search {
  background: #001c25 url(../images/bg/bg-top-menu-sprites.png) repeat-x;
  background-position: -10px -196px;
  width: 38.333%;
  height: 40px;
  z-index: 200;
  position: absolute;
  top: 0;
  right: 0;
}
.block-search h2 {
  display: none;
}
.block-search form .form-type-textfield {
  position: relative;
  display: inline-block;
  margin-left: 5px;
  height: 40px;
  width: 80%;
}
.block-search form label {
  position: absolute;
  left: 10px;
  top: 0px;
  color: #fff;
  font-size: 0.750em;
  font-family: "proxima-nova", Helvetica,Arial,sans-serif;
  font-weight: 800;
  letter-spacing: 0.125em;
  text-transform: uppercase;
  height: 40px;
  line-height: 40px;
  display: inline-block;
}
.block-search form input.form-text {
  font-size: em(13);
  padding: 5px 0px 4px 10px;
  color: #fff;
  font-style: italic;
  line-height: 31px;
  width: 95%;
  height: 31px;
  background-color: #001c25;
  border: none;
}
.block-search input[type="submit"],
.block-search form input.form-submit {
  position: absolute;
  right: 10px;
  top: 10px;
  padding: 0;
  background: url(../images/ui/ui-arrows-sprites.png) no-repeat;
  background-position: -1px -1051px;
  height: 19px;
  width: 19px;
  display: block;
  overflow: hidden;
  text-indent: -9999px;
  border: 0;
  cursor: pointer;
  line-height: 0px;
}
.block-search input[type="submit"]:hover,
.block-search form input.form-submit:hover {
  background-position: -1px -1182px;
}