/* Site Layout - START
*********************************/
.container {
  margin: 0 auto; 
  max-width: 940px;
  width: 93%;
}
.main-content {
  margin: 0 auto;
  padding: em(40) 0 em(30) 0;
  width: 100%;
}
.node-type-academic-program .main-content {
  min-height: inherit;
}
.page-views .main-content {
  padding-top: 0;
}
.border {
  min-height: auto;
  border: 20px solid #fff;
}
/* Page BG - START
*********************************/
.node-type-gallery #page,
.node-type-academic-degree #page,
.node-type-basic #page,
.node-type-event #page,
.node-type-alum #page,
.node-type-faculty #page {
  background-color: transparent;
}
.node-type-gallery #main,
.node-type-academic-degree #main,
.node-type-basic #main,
.node-type-event #main,
.node-type-feature-block #main,
.node-type-alum #main,
.node-type-faculty #main,
.node-type-webform #main{
  background: #fff;
  height: auto !important;
  height: 100%;
  margin: 20px 20px 5px 20px;
  padding-bottom: 150px;
}
/* Page Title - Block */
.page-node-lecture-series.page-views .block-page-title,
.page-node-degree.page-views .block-page-title,
.page-node-alumni.page-views .block-page-title,
.page-student-life-the-what.page-views .block-page-title,
.page-taxonomy-term .block-page-title,
.node-type-basic .block-page-title,
.node-type-event .block-page-title,
.node-type-gallery .block-page-title,
.node-type-webform .block-page-title,
.node-type-alum .block-page-title,
.node-type-faculty .block-page-title,
.node-type-academic-degree .block-page-title,
.node-type-media-page .block-page-title,
.node-type-feature-block .block-page-title,
.section-blog .block-page-title {
  margin: 0 0 0 0;
  @include background-cover;
  filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/sites/tyler/themes/tyler/images/bg/bg-page-title-overlay.png', sizingMethod='scale');
  -ms-filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/sites/tyler/themes/tyler/images/bg/bg-page-title-overlay.png', sizingMethod='scale')";
  min-height: 175px;
}
.block-page-title{
  height: auto;
  position: relative;
  overflow: hidden;
  min-height: 175px;
}
.block-page-title h1,
body.page-student-life.page-views .block-page-title h1,
.page-node-degree.page-views .block-page-title h1,
.page-node-alumni.page-views .block-page-title h1,
.page-node-lecture-series.page-views .block-page-title h1 {
  margin: 0 0 0 0;
  padding: 20px 0 10px 0;
  width: 65%;
  height: 160px;
  vertical-align: middle;
  display: table-cell;
  padding: 0;
  border-bottom: none;
}
body.page-node-student-work.page-views {
  .block-page-title { 
    margin-top: 60px;
    min-height: 0;
  }
}
/* Views Page Title - Block */
.page-views .block-page-title {
  margin: 60px 0 0 0;
  min-height: 65px;
  display: block;
  overflow: inherit;
  border: none;
}
.page-node-student-work .block-page-title h1,
.page-student-exhibitions.page-views h1 {
  color: $grey;
}
.page-views .block-page-title h1,
.page-views .block-page-title h5 {
  display: block;
  margin: 0 0 0 0;
  width: 100%;
  height: auto;
  word-wrap: break-word;
}
.page-views .block-page-title h5 {
  padding: 10px 0 10px 0;
}
.page-views .block-page-title .title-bg {
  min-height: inherit;
}
.page-events-list.page-views .block-page-title{
  display: block;
  overflow: inherit;
  height: auto;
  margin-top: 60px;
  min-height: 35px;
}
.page-views .main-content {
  padding-top: 0;
  min-height: 475px;
}
.node-type-continuing-education-program .block-page-title{
  margin: 40px 0 -55px 0;
}
/* Programs Hero - Block */
.block-program-hero {
  margin: 0;
  background: #fff;
  position: relative;
  overflow: hidden;
  height: auto;
  max-height: 465px;
}
/* Regions */
.region-content {
  width: 72.55%;
  float: left;
  margin-bottom: 20px;
}
.node-type-academic-program .region-content .field-body > p:first-child,
.node-type-landing-page .region-content .field-body > p:first-child {
  line-height: 1.250em;
  font-weight: 700;
  margin-bottom: 0.313em;
  color: $darkBlue;
  font-size: 1.250em;
  padding-top: 0;
}
.node-type-academic-program .element-hidden,
.node-type-landing-page .element-hidden {
  display: none;
}
.sidebars {
  padding: 30px 0 0 0;
}
.sidebar-second .main-content {
  width: 72.55%;
  float: left;
}
.region-sidebar-first {
  padding-right: 20px;
  width: 25%;
  float: left;
  margin-bottom: 20px;
}
.region-sidebar-second {
  float: right;
  padding-left: 20px;
  width: 25%;
}
.region-sidebar-first,
.region-sidebar-second {
  &.col-one-quarter {
    width: 25%;
  }
}
.block-quick-links {
  width: 100%;
  float: none;
  margin-left: 0;
  background-color: $deepGrey;
}
.section-programs #main {
  padding-bottom: 30px;
}
/* Programs Degree Node - START
*********************************/
.degree-info-boxes li,
.event-info-boxes li {
  background-color: $deepGrey;
  margin-bottom: 20px;
  min-height: 120px;
  h3 {
    margin-bottom: em(10);
    color: #fff;
  }
  .content {
    padding: 20px 20px;
  }
  width: 100%;
  float: none;
  margin-left: 0;
}

.views-row-first .node-academic-degree {
  padding: em(40) 0 em(20) 0;
}
.page-node-alumni h1,
.page-node-degree h1 {
  margin-bottom: 45px;
}
/* Faculty & Alumni- START
*********************************/
.view-alumni .view-content {
  float: left;
  margin: 50px 0;
  width: 72.55%;
}
/* Profile Box - START
*********************************/
.profile-box.grid-two .col-one-quarter {
  width: 29%;
  padding: 25px 0 25px 25px;
}
.profile-box.grid-two .col-three-quarters {
  width: 67%;
}
.course-popup .profile-box.grid-two .col-one-quarter {
  width: 29%;
  padding: 0 0 0 0;
}
/* Continuing Education - START
*********************************/
.section-continuing-education-program .grid-view h2 a {
  min-height: 54px;
}
/* Landing Page - START
*********************************/
.section-admission-process .grid-view .col-one-third {
  width: 25%;
}
.section-admission-process #block-program-masonry .masonry-title .block-label {
  width: 100%;
  background-color: transparent;
  font-size: 0.750em;
  font-weight: 800;
  text-shadow: 1px 0px 1px #fff;
  border-bottom: 3px solid #fff;
}
/* Event Category Page - START
*********************************/
.node-type-event .field-featured-image img {
  width: auto;
}

/* Event Info Boxes */
.event-info-boxes a {
  background: url(../images/ui/ui-arrows-sprites.png) no-repeat;
  background-position: 100% -431px;
  padding-right: 20px;
  &:link, &:visited {
      color: $darkBlue;
  }
  &:hover, &:active {
    color: #4d0808;
  }
}

/* Blog - START
*********************************/
.sidebar-second.section-blog .main-content,
.sidebar-second.section-tags .main-content,
.sidebar-second.section-blog-categories .main-content {
  float: left;
  width: 65.99%;
}
/* Facilities - START
*********************************/
.page-facilities .block-page-title {
  h5 { margin: 60px 0 10px 0;}
}
.page-facilities ul#gridlist-views.grid,
.page-facilities #facility-wrap .wrapper,
.page-facilities .facilities-intro,
.page-facilities .block-page-title{ 
  width: 940px;
  max-width: 940px;
  margin: 0 auto;
  float: none;
}
.field-panorama {
  p {
    padding: 0; 
    line-height: 0;
  }
  iframe {
    height: 600px;
  }
}
.page-facilities #facility-wrap .wrapper .left {
  p { width: 95%; color: #fff;}
  padding: 20px 0 20px 0;
}
#facility-wrap .gallerySlider .flex-direction-nav {
  right: 15%;
  bottom: -19%;
  display: block;
}