/* Events Block */
.front #block-events {
	overflow: inherit;
}
#block-events {
	overflow: hidden;
	position: relative;
	width: 100%;
	height: auto;
	margin: 0;
	padding-bottom: 65px;
	background: #5a9156 url("../images/bg/bg-events-section.jpg") no-repeat;
	@include background-cover;
}
#block-events .col-one-half {
	width: 50%;
}
#block-events .flex-slide,
#block-blog .flexCarousel {
	max-width: 940px; 
  	min-width: 320px;
    width: 100%;
    width: 93%;
}
#block-events ul.slides {
	padding: 35px 0 0 0;
}
#block-events ul.slides li.item {
	position: relative;
	height: auto;
	width: auto;
	margin-right: em(25);
}
#block-events .featured-content {
	padding-right: em(25);
	width: 50%;
}
#block-events .list-content {
	width: 49%;
}
#block-events .list-content ul li {
	padding: 0;
	margin-bottom: 0;
	background: none;
	overflow: visible;
	h4 { font-size: 0.938em;}

	&:first-child h5.title{
        display: block;
    }
	h5.title { display: none;}
}
#block-events ul li ul.event-info li:after {
	color: #396836;
}
#block-events .list-content p.description {
	width: 90%;
	padding: 0.625em 0 1.250em 0;
}
#block-events .flex-slide ul.flex-direction-nav {
	top: 8em;
    right: 1.250em;
}
#block-events .slide-control {
	position: absolute;
	top: -18px;
	right: 0;
	width: 318px;
	height: 321px;
	display: block;
	z-index: 20;
    background: url(../images/bg/bg-events-section-control.png) no-repeat;
    background-position: 0 0;
    &:before{
		display: none;
	}
}
#block-events .slide-control h1 {
	color: #fff;
    text-transform: uppercase;
	width: 70%;
	font-size: 2.813em;
	text-align: right;
	float: right;
	font-weight: 100;
	line-height: 1.04em;
	letter-spacing: none;
	margin: 52px 20px 0 0;

	span {
		display: none;
	}
}
/* Blog Block */
.node-type-academic-program #block-blog,
.node-type-landing-page #block-blog {
	clear: both;
	float: left;
	width: 100%;
	height: auto;
	height: 325px;
	padding-bottom: 100px;
	position: relative;
	border-top: 2px solid #fff;
	background-color: #c4d8c8;
	@include background-cover;
	filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/sites/tyler/themes/tyler/images/bg/bg-blog-section.jpg', sizingMethod='scale');
	-ms-filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/sites/tyler/themes/tyler/images/bg/bg-blog-section.jpg', sizingMethod='scale')";
}
.front #block-blog {
	border-top: 2px solid #e3e3e2;
}
#block-blog {
	width: 100%;
	position: relative;
	height: auto;
	height: 325px;
	padding-bottom: 100px;
	@include background-cover;
	filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/sites/tyler/themes/tyler/images/bg/bg-blog-section.jpg', sizingMethod='scale');
	-ms-filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/sites/tyler/themes/tyler/images/bg/bg-blog-section.jpg', sizingMethod='scale')";
}
#block-blog .flexCarousel{
	margin: 0 0 0 -20px;
	padding: 0 0 0 0;
	height: auto;
}
#block-blog ul.slides{
	position: relative;
	padding: 52px 0 0 0;
}
#block-blog ul.slides > li,
#block-blog ul.slides > li.item {
	position: relative;
	cursor: pointer;
	clear: none;
	margin-bottom: 0;
	padding-bottom: 10px;
	background: none;
	overflow: visible;
	height: auto;
}
#block-blog ul.slides li .image{
	z-index: 20;
	display: block;
}
#block-blog ul.slides li .item-content{
	position: relative;
	z-index: 20;
	width: 100%;
	padding: 20px 0 0 0;
	border-top: 3px solid #fff;

	h3 {
		font-size: 1.250em;
	}
	p {
		font-size: em(12);
	}
}

#block-blog ul.field-tags {
	display: inline-block;
}
#block-blog ul.slides li:hover .share-content {
	display: block;
}
#block-blog .flexCarousel ul.flex-direction-nav {
	 top: 11.875em;
	 right: 1.250em;
}
#block-blog .slide-control {
	position: absolute;
	top: 0;
	right: 0;
	width: 350px;
	height: 350px;
	display: block;
	z-index: 50;
    background: url(../images/bg/bg-blog-section-control.png) no-repeat;
    background-position: 0 0;
    &:before{
		display: none;
	}
}
#block-blog .slide-control h1 {
	width: 70%;
	text-transform: uppercase;
	font-size: 2.813em;
	text-align: right;
	float: right;
	color: #003e51;
	font-weight: 100;
	line-height: 1.04em;
	letter-spacing: none;
	margin: 52px 20px 0 0;
}
#block-blog .slide-control a.button {
	border: 1px solid #5890a0;
	margin: 10px 20px 0 0;
	padding-right: 34px;
	float: right;
	display: block;
}
#block-events .mobile-link,
#block-blog .mobile-link {
	display: none;
}
/* Region Bottom Highlight */
#bottom-highlight {
	overflow: hidden;
	position: relative;
	width: 100%;
	height: auto;
	min-height: 145px;
	background: #fff;
	.container {
		width: 100%;
	}
}
/* Contact Block */
.block-cont-ed-contact,
.block-temple-con-contact {
	width: 62.55%;
	height: 110px;
	margin: 0 0;
	padding-bottom: 20px;
	float: left;
	border-right: 1px solid #e7e7e7;
}
/* Program Degree Block & Admission Timeline Block List*/
#block-admissions-timeline {
	width: auto;
	margin: 0 0 0 0;
	padding: 50px 0 20px 0;
}
#block-admissions-timeline h1 {
  position: absolute;
  margin-top: -95px;
}
#block-program-degree {
	padding: 0;
	width: 100%;
	height: auto;
	overflow: hidden;
	.content {
		padding: 50px 0 40px 0;
	}
}
#block-program-degree .content,
#block-admissions-timeline .content {
	margin-left: -20px;
}
#block-program-degree .grid,
#block-admissions-timeline .grid {
	padding: 0 0 0 1.250em;
}
#block-program-degree .image {
	margin: 0 0;
	position: relative;
}
#block-program-degree .image img {
	width: 440px;
}
#block-program-degree .image,
#block-program-degree .callout-link {
	margin: 0 0;
}
.bg-admission-blue {
	background: #b7e8ee url("../images/bg/bg-admission-blue.jpg") no-repeat top left;
	@include background-cover;
	moz-box-shadow: inset 0 0 20px #98c3cc;
	-webkit-box-shadow: inset 0 0 20px #98c3cc;
	box-shadow: inset 0 0 20px #98c3cc;
}
.bg-lime {
	moz-box-shadow: inset 0 0 20px #5b874b;
	-webkit-box-shadow: inset 0 0 20px #5b874b;
	box-shadow: inset 0 0 20px #5b874b;
	background-color: #73a85e;
	@include background-cover;
	filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/sites/tyler/themes/tyler/images/bg/bg-degree-green.jpg', sizingMethod='scale');
	-ms-filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/sites/tyler/themes/tyler/images/bg/bg-degree-green.jpg', sizingMethod='scale')";
}
.bg-cream {
	moz-box-shadow: inset 0 0 20px #6b8882;
	-webkit-box-shadow: inset 0 0 20px #6b8882;
	box-shadow: inset 0 0 20px #6b8882;
	background-color: #fffbdd;
	background-position: 0 0;
	filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/sites/tyler/themes/tyler/images/bg/bg-degree-cream.jpg', sizingMethod='scale');
	-ms-filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/sites/tyler/themes/tyler/images/bg/bg-degree-cream.jpg', sizingMethod='scale')";
}
.bg-cream h5 {
	border-top: 3px solid #fff;
	color: #fff;
}
/* Alumni Spotlight Block */
#block-spotlight .container {
	width: 93%;
	min-width: 320px;
}
#block-spotlight .image{
	position: inherit;
	width: 30.333%;
	margin: 0 0 0 -20px;
}
#block-spotlight .bio-text {
	padding-top: 25px;
	float: left;
	width: 69.666%;
	border-top: 3px solid $darkBlue;
}
#block-spotlight .bio-text li {
	float: left;
	width: 46%;
	padding-left: em(20);
}
/* Spotlight Slidehow Block */
body.node-type-landing-page #block-spotlight-slide{
	border-top: none;
	border-bottom: none;
	border-left: 20px solid #fff;
	border-right: 20px solid #fff;
}
body.node-type-landing-page #block-spotlight-slide .container {
	width: 940px;
}
#block-spotlight-slide .container {
	width: 940px;
}
#block-spotlight-slide .slide-control,
#instructor-slide .slide-control {
	width: 180px;
	height: 175px;
	background-position: 98% -1357px;
}
#block-spotlight-slide .flexslider .flex-direction-nav{
	top: 35px;
	right: 4%;
}
#block-spotlight-slide .flex-control-nav,
#instructor-slide .flex-control-nav {
	top: 40px;
}
#block-spotlight-slide .field-body {
	padding-top: 30px;
	border-top: 3px solid #fff;
	margin-left: -20px;
}
/* Newsletter Block */
.block-newsletter {
	height: auto;
	right: 0;
    top: 0;
	width: 40%;
	float: left;
	position: absolute;
	border-top: none;
	padding-bottom: 0;
	span {
		display: none;
	}
}
.block-newsletter .subscribe-label {
    display: inline-block;
    font-size: 0.875em;
    text-transform: capitalize;
    margin: 18px 0 0 20px;
}