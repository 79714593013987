#breadcrumb {
	width: 100%;
	height: auto;
	display: block;
	background: #817e7b;
	-moz-box-shadow: inset 0 0 10px #716f6c;
		-webkit-box-shadow: inset 0 0 10px #716f6c;
		box-shadow: inset 0 0 10px #716f6c;
	.container {
		width: auto;
		height: 50px;
		padding: 20px 0 20px 0px;
	}
	.last-menu-holder {
			max-width: 50%;
	}
	&.green-color {
		background: $green;
		-moz-box-shadow: inset 0 0 10px $darkGreen;
		-webkit-box-shadow: inset 0 0 10px $darkGreen;
		box-shadow: inset 0 0 10px $darkGreen;

		.last-menu-holder ul li a:after {
			color: $darkGreen;
	    }
	}
	&.cyan-color {
		background: $cyan;
		-moz-box-shadow: inset 0 0 10px $darkCyan;
		-webkit-box-shadow: inset 0 0 10px $darkCyan;
		box-shadow: inset 0 0 10px $darkCyan;
		.last-menu-holder ul li a:after {
	    	color: $darkCyan;
	    }
	}
	&.orange-color {
		background: $orange;
		-moz-box-shadow: inset 0 0 10px $darkOrange;
		-webkit-box-shadow: inset 0 0 10px $darkOrange;
		box-shadow: inset 0 0 10px $darkOrange;
		.last-menu-holder ul li a:after {
	    	color: $darkOrange;
	    }
	}
}
#breadcrumb  .format-list .collapsed-menu-holder ul.menu.menu-column-1,
#breadcrumb  .format-list .collapsed-menu-holder ul.menu.menu-column-3 {
	display: inline-block;
}
#breadcrumb .collapsible-menu-wrapper .last-menu-holder{
	width: auto;
	clear:none;
	float: left;
	text-align: left;
	margin: -4px 0 -4px 0;
	overflow: hidden;

	ul {
		vertical-align: middle;
		display: table-cell;
		height: 60px;
	}
}
#breadcrumb  .last-menu-holder.col-one-half {
	width: 45%;
}
.page-taxonomy #breadcrumb .last-menu-holder,
.node-type-landing-page #breadcrumb .last-menu-holder {
	width: 73%;
}
.page-academic-programs #breadcrumb .last-menu-holder{
	width: 74.55%;
}
#breadcrumb  .collapsible-menu-wrapper .collapsed-menu-holder a {
	line-height: em(20);
  	height: 50px;
  	vertical-align: middle;
    display: table-cell;
	padding: 0px 35px 0px 15px;
}
#mobile-breadcrumb-toggle {
	display: none;
}
.block-collapsible-menu .line,
.block-collapsible-menu .line.close {
	display: none;
}