/** Sorting Bar **/

.sort-bar ul {
	width: auto;
}
.sort-bar ul li {
	width: auto;
	border-bottom: none;
}
.sort-bar ul li a {
	padding: 0 15px 0 15px;
	width: auto;
	text-align: left;
	border-right: 1px solid #fff;
    &:hover, &:active {
    	background-color: #eaeaea;
    }
    &.active, &.current {
    	background-color: #eaeaea;
    	color: $red;
    }
}
.sort-bar.dark-bg ul li a {
  &:hover, &:focus {
    color: $darkBlue;
    background-color: #eaeaea;
  }
}
.sort-bar ul.view-switch li a {
	padding: 0 20px 0 60px;
}
.sort-bar ul.view-switch li,
.sort-bar ul.view-switch li a.list {
	border-right: 0px solid;
}
.sort-bar ul.view-switch li a span,
.sort-bar ul.view-switch li a span,
#block-program-masonry .masonry-title span {
	display: inline-block;
}
.sort-bar .filter-dropdown ul,
.sort-bar .filter-dropdown.active a.label-title {
	background: url(../images/bg/bg-transparent-green.png) repeat;
}
ul#gridlist-views.list li {
	cursor: pointer;
	position: relative; 
	height: auto;
	padding: 0;
	margin-bottom: 30px; 
	display: block;
	clear: right;
	float: left;
	background: none;
}
ul#gridlist-views.list li .right {
	display: block; 
	float: right;
	width: 74%;
	padding: 0 0;
}
ul#gridlist-views.list li .description {
	display: inline-block;
	width: 63%;
	float: left;
	display: block;
}
/** grid view **/
ul#gridlist-views.grid li {
	cursor: pointer;
	position: relative; 
	display: block; 
	float: left; 
	height: auto;
	margin: 0 10px 40px 10px;
	box-sizing: border-box; 
	-moz-box-sizing: border-box; 
	-webkit-box-sizing: border-box;
}
.block-views .fader {
	background: url(../images/bg/bg-transparent-white.png) repeat;
}